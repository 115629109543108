import { DeleteOutlined, InboxOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  BackButton,
  ConfirmDeleteModal,
  DetailsPageLayout,
  HeaderSeparator,
  PageContentDropdown,
  Spacer,
  SubHeader,
} from '../../components'
import { privacyTypes } from '../../constants/constants'
import { urlList } from '../../constants/urlConstant'
import useWorkspaceDetails from '../../hooks/useWorkspaceDetails'
import useWorkspaces from '../../hooks/useWorkspaces'
import { apiService } from '../../services'
import notificationService from '../../services/notificationService'
import workSpaceService from '../../services/workspaceService'
import { RootState } from '../../store'
import { routePaths } from '../RootPage'
import SelectWorkspacePanelType from './components/SelectWorkspacePanelType'
import WorkspaceTabs from './components/WorkspaceTabs'
import WorkspaceDetailsSelectParticipantModal from './components/modals/WorkspaceDetailsAddParticipant/WorkspaceDetailsAddParticipantModal'
import WorkspaceDetailsAddStoreModal from './components/modals/WorkspaceDetailsAddStore/WorkspaceDetailsAddStoreModal'
import {
  WorkspaceOverviewInfoPanel,
  WorkspaceSettingsInfoPanel,
  WorkspaceSettingsPeoplePanel,
  WorkspaceSettingsSourcePanel,
  WorkspaceSettingsStorePanel,
  WorkspaceSmartFunctionPanel,
} from './panels'

const FloatLeftContainer = styled.div`
  display: flex;
  margin-left: auto;
  order: 2;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
`

export type WorkspacePanelType = 'overview' | 'settings' // The use of camel case is to have camel case in the url
export type WorkspacePanelTypes = {
  OVERVIEW: WorkspacePanelType
  SETTINGS: WorkspacePanelType
}

export const workspacePanelTypes: WorkspacePanelTypes = {
  OVERVIEW: 'overview',
  SETTINGS: 'settings',
}

export type WorkspaceTab = 'info' | 'smartFunction' | 'source' | 'people' // The use of camel case is to have camel case in the url
export type WorkspaceTypes = {
  INFO: WorkspaceTab
  SMART_FUNCTION: WorkspaceTab
  DATA: WorkspaceTab
  PEOPLE: WorkspaceTab
}

export const workSpaceTabs = {
  INFO: 'info',
  SMART_FUNCTION: 'smartFunction',
  SOURCE: 'source',
  STORE: 'store',
  PEOPLE: 'people',
}

// singleWorkspace is for client using iframe and are allowed to see only one workspace
interface WorkspaceDetailsPageProps {
  singleWorkspace?: boolean
}

const WorkspaceDetailsPage = ({
  singleWorkspace,
}: WorkspaceDetailsPageProps) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { panelType, tab, workspaceId } = useParams()
  const userList = useSelector((state: RootState) => state.user.users)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [fetchDone, setFetchDone] = useState(false)

  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<
    string | undefined
  >(workspaceId)

  const [selectedWorkspacePanelType, setSelectedWorkspacePanelType] = useState(
    (panelType || workspacePanelTypes.OVERVIEW) as WorkspacePanelType
  )
  const [selectedWorkspaceTab, setSelectedWorkspaceTab] =
    useState<WorkspaceTab>((tab || workSpaceTabs.INFO) as WorkspaceTab)
  const {
    selectedWorkspace,
    refreshWorkspaceDetails,
    exportWorkspaceDataSourcePanelCsv,
    exportWorkspaceDataStorePanelCsv,
    exportWorkspaceParticipantsPanelCsv,
    exportWorkspaceDetailsPanelCsv,
    exportWorkspaceAlgorithmRunsListCsv,
  } = useWorkspaceDetails({
    workspaceId: currentWorkspaceId,
    preventFetch: true,
  })
  const { refreshWorkspaceList } = useWorkspaces({
    preventFetch: true,
  })
  const defaultValues = useMemo(
    () => ({
      title: selectedWorkspace?.title,
      description: selectedWorkspace?.description,
      visibility: selectedWorkspace?.visibility,
      participants: selectedWorkspace?.participants?.map(
        (participant) => participant.id
      ),
    }),
    [selectedWorkspace]
  )

  const participantListLength = useMemo(() => {
    if (selectedWorkspace?.visibility === privacyTypes.PRIVATE) {
      return selectedWorkspace.participants?.length || 0
    } else {
      return userList ? userList.length : 0
    }
  }, [userList, selectedWorkspace])

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  useEffect(() => {
    if (!fetchDone) {
      refreshWorkspaceDetails()
      setFetchDone(true)
    }
  }, [fetchDone, refreshWorkspaceDetails])

  useEffect(() => {
    if (currentWorkspaceId !== workspaceId) {
      setCurrentWorkspaceId(workspaceId)
      setSelectedWorkspacePanelType(workspacePanelTypes.OVERVIEW)
      setFetchDone(false)
    }
  }, [workspaceId, selectedWorkspace, currentWorkspaceId])

  useEffect(() => {
    setSelectedWorkspacePanelType(
      (panelType || workspacePanelTypes.OVERVIEW) as WorkspacePanelType
    )
    setSelectedWorkspaceTab((tab || workSpaceTabs.INFO) as WorkspaceTab)
  }, [panelType, tab])

  const handlePanelTypeChange = useCallback(
    (value: WorkspacePanelType | undefined) => {
      setSelectedWorkspaceTab(workSpaceTabs.INFO as WorkspaceTab)
      const baseRoute = singleWorkspace
        ? routePaths.SINGLE_WORKSPACE_DETAILS
        : routePaths.WORKSPACE_DETAILS
      navigate(
        `${baseRoute}/${workspaceId}/${value || workspacePanelTypes.OVERVIEW}/${
          workSpaceTabs.INFO
        }`
      )
    },
    [navigate, singleWorkspace, workspaceId]
  )

  const panelIndex = useCallback(() => {
    if (selectedWorkspacePanelType === workspacePanelTypes.OVERVIEW) {
      switch (selectedWorkspaceTab) {
        case workSpaceTabs.INFO:
          return 0
        case workSpaceTabs.SMART_FUNCTION:
          return 2
      }
    } else if (selectedWorkspacePanelType === workspacePanelTypes.SETTINGS) {
      switch (selectedWorkspaceTab) {
        case workSpaceTabs.INFO:
          return 1
        case workSpaceTabs.SOURCE:
          return 3
        case workSpaceTabs.STORE:
          return 4
        case workSpaceTabs.PEOPLE:
          return 5
      }
    }
    return 0
  }, [selectedWorkspacePanelType, selectedWorkspaceTab])

  const handleDelete = useCallback(() => {
    setConfirmDeleteModalOpen(true)
  }, [])

  const handleConfirmDelete = useCallback(() => {
    setDeleteLoading(true)
    if (workspaceId === undefined) {
      return
    }
    apiService
      .deleteItem(`${urlList.WORKSPACES}/${workspaceId}`, navigate)
      .then(() => {
        refreshWorkspaceList()
        notificationService.notificationSuccess(
          t('workspaceDetailsPage.deleteWorkspaceSuccessful') || ''
        )
        navigate(routePaths.WORKSPACE_LIST)
        setDeleteLoading(false)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setDeleteLoading(false)
      })
  }, [refreshWorkspaceList, navigate, workspaceId])

  const validatePrivateWorkspaceParticipants = useCallback(
    (participants: string[]): boolean => {
      if (participants.length === 0) {
        notificationService.notificationError(
          t('workspaceDetailsPage.mustHaveOneParticipant')
        )
        return false
      }

      if (
        selectedWorkspace?.createdBy &&
        !participants.includes(selectedWorkspace?.createdBy)
      ) {
        notificationService.notificationError(
          t('workspaceDetailsPage.mustHaveOwnerParticipant')
        )
        return false
      }

      return true
    },
    [selectedWorkspace?.createdBy]
  )

  const handleSave = useCallback(() => {
    setSaveLoading(true)
    const values = form.getFieldsValue()
    if (values.visibility === privacyTypes.PRIVATE) {
      const isValid = validatePrivateWorkspaceParticipants(values.participants)
      if (!isValid) {
        setSaveLoading(false)
        return
      }
    }
    workSpaceService
      .updateWorkspaceDetails({
        workspaceId,
        ...values,
      })
      .then(() => {
        notificationService.notificationSuccess(
          t('workspaceDetailsPage.updateWorkspaceSuccessful') || ''
        )
        setSaveLoading(false)
        refreshWorkspaceDetails()
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setSaveLoading(false)
      })
  }, [
    form,
    refreshWorkspaceDetails,
    workspaceId,
    setSaveLoading,
    validatePrivateWorkspaceParticipants,
  ])

  const handleWorkspaceDetailsPanelExport = () => {
    if (selectedWorkspaceTab === workSpaceTabs.INFO) {
      exportWorkspaceDetailsPanelCsv(selectedWorkspace?.id || '')
    } else if (selectedWorkspaceTab === workSpaceTabs.SOURCE) {
      exportWorkspaceDataSourcePanelCsv(selectedWorkspace?.id || '')
    } else if (selectedWorkspaceTab === workSpaceTabs.STORE) {
      exportWorkspaceDataStorePanelCsv(selectedWorkspace?.id || '')
    } else if (selectedWorkspaceTab === workSpaceTabs.PEOPLE) {
      exportWorkspaceParticipantsPanelCsv(selectedWorkspace?.id || '')
    } else if (selectedWorkspaceTab === workSpaceTabs.SMART_FUNCTION) {
      exportWorkspaceAlgorithmRunsListCsv(selectedWorkspace?.id || '')
    }
  }

  const panels = useMemo(
    () => [
      <WorkspaceOverviewInfoPanel />,
      <WorkspaceSettingsInfoPanel form={form} />,
      <WorkspaceSmartFunctionPanel singleWorkspace={singleWorkspace} />,
      <WorkspaceSettingsSourcePanel />,
      <WorkspaceSettingsStorePanel />,
      <WorkspaceSettingsPeoplePanel />,
    ],
    [form, singleWorkspace]
  )

  return (
    <DetailsPageLayout>
      <SubHeader>
        {!singleWorkspace && <BackButton url={routePaths.WORKSPACE_LIST} />}
        <SelectWorkspacePanelType
          onChange={(value) => handlePanelTypeChange(value)}
        />
        <HeaderSeparator />
        <WorkspaceTabs singleWorkspace={singleWorkspace} />
        {selectedWorkspacePanelType === workspacePanelTypes.SETTINGS &&
          selectedWorkspaceTab === workSpaceTabs.SOURCE && (
            <>
              <InboxOutlined />
              {t('workspaceDetailsPage.totalFound', {
                count: selectedWorkspace?.dataSource ? 1 : 0, // replace with data count from api when changed
              })}
            </>
          )}
        {selectedWorkspacePanelType === workspacePanelTypes.SETTINGS &&
          selectedWorkspaceTab === workSpaceTabs.STORE && (
            <>
              <InboxOutlined />
              {t('workspaceDetailsPage.totalFound', {
                count: selectedWorkspace?.dataStores?.length || 0,
              })}
              <Spacer />
              <div>
                <WorkspaceDetailsAddStoreModal />
              </div>
            </>
          )}
        {selectedWorkspacePanelType === workspacePanelTypes.SETTINGS &&
          selectedWorkspaceTab === workSpaceTabs.PEOPLE && (
            <>
              <InboxOutlined />
              {t('workspaceDetailsPage.totalFound', {
                count: participantListLength,
              })}
              <Spacer />
              <>
                <WorkspaceDetailsSelectParticipantModal />
              </>
            </>
          )}
        {selectedWorkspaceTab === workSpaceTabs.INFO && (
          <>
            <Spacer />
            {!singleWorkspace && (
              <Button danger icon={<DeleteOutlined />} onClick={handleDelete}>
                {t('workspaceDetailsPage.deleteButton')}
              </Button>
            )}
            <ConfirmDeleteModal
              open={confirmDeleteModalOpen}
              onConfirm={handleConfirmDelete}
              onCancel={() => setConfirmDeleteModalOpen(false)}
              loading={deleteLoading}
            >
              {t('workspaceListPage.confirmDeleteModalContent')}
            </ConfirmDeleteModal>
            {panelType === workspacePanelTypes.SETTINGS && (
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={handleSave}
                loading={saveLoading}
              >
                {t('saveButton')}
              </Button>
            )}
          </>
        )}
        <FloatLeftContainer>
          <PageContentDropdown onExport={handleWorkspaceDetailsPanelExport} />
        </FloatLeftContainer>
      </SubHeader>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={defaultValues}
        onFinish={handleSave}
      >
        {panels[panelIndex()]}
      </Form>
    </DetailsPageLayout>
  )
}

export default WorkspaceDetailsPage
