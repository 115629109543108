import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavigateFunction } from 'react-router-dom'
import { urlList } from '../constants/constants'
import { apiService, smartFunctionService } from '../services'
import { RootState } from '../store'
import {
  AlgorithmRun,
  AlgorithmRunConfigValues,
  Workspace,
  setSelectedAlgorithmRun
} from '../store/reducers/workspaceReducer'
import { HttpResponse } from '../types/generalTypes'

export interface WorkspaceResponse extends HttpResponse {
  data: Workspace[]
}

interface UseWorkspaceListProps {
  workspaceId?: string
  algorithmRunId?: string
  preventFetch?: boolean
}

const useAlgorithmRunDetails = ({
  workspaceId,
  algorithmRunId,
  preventFetch = false,
}: UseWorkspaceListProps) => {
  const dispatch = useDispatch()
  const selectedAlgorithmRun = useSelector(
    (state: RootState) => state.workspace.selectedAlgorithmRun
  )

  const [error, setError] = useState<AxiosError | Error>()
  const [loading, setLoading] = useState(false)

  const fetchAlgorithmRun = useCallback(
    async (refreshForceCall = false) => {
      if (!algorithmRunId || algorithmRunId === '0' || !workspaceId) {
        return
      }
      if (!loading && (!preventFetch || refreshForceCall)) {
        setLoading(true)
        apiService
          .fetchItems(
            `${urlList.WORKSPACES}/${workspaceId}/algorithmRuns/${algorithmRunId}`
          )
          .then((response: AlgorithmRun) => {
            setLoading(false)
            dispatch(setSelectedAlgorithmRun(response))
          })
          .catch((error) => {
            setLoading(false)
            console.error('axios save search error', error)
            setError(error)
          })
      }
    },
    [dispatch, workspaceId, algorithmRunId, loading, preventFetch]
  )

  const runAlgorithm = useCallback(
    async (
      workspaceId: string,
      algortihmRunConfig: AlgorithmRunConfigValues | undefined
    ): Promise<AlgorithmRun> => {
      return new Promise((resolve, reject) => {
        if (!workspaceId) {
          reject(new Error('Invalid workspace or algorithm run ID'))
          return
        }
        setLoading(true)
        smartFunctionService
          .runAlgorithm({
            id: workspaceId,
            config: algortihmRunConfig,
          })
          .then((response: AlgorithmRun) => {
            resolve(response)
          })
          .catch((error: AxiosError | Error) => {
            setLoading(false)
            console.error('axios fetch error', error)
            reject(error)
          })
      })
    },
    [setLoading]
  )

  const cancelAlgorithmRun = useCallback(
    async (
      workspaceId: string,
      algorithmRunId: string,
      navigate: NavigateFunction
    ) => {
      return new Promise((resolve, reject) => {
        if (!workspaceId || !algorithmRunId) {
          reject(new Error('Invalid workspace or algorithm run ID'))
          return
        }
        setLoading(true)
        smartFunctionService
          .cancelRun({
            id: workspaceId,
            algorithmRunId,
            navigate,
          })
          .then((response: AlgorithmRun) => {
            resolve(response)
          })
          .catch((error: AxiosError | Error) => {
            setLoading(false)
            console.error('axios fetch error', error)
            reject(error)
          })
      })
    },
    [setLoading]
  )

  const refreshAlgorithmRun = useCallback(async () => {
    fetchAlgorithmRun(true)
  }, [fetchAlgorithmRun])

  useEffect(() => {
    fetchAlgorithmRun()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algorithmRunId])

  return {
    fetchAlgorithmRun,
    refreshAlgorithmRun,
    runAlgorithm,
    cancelAlgorithmRun,
    selectedAlgorithmRun,
    loading,
    error,
  }
}

export default useAlgorithmRunDetails
