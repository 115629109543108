import component from './component-fr.json'
import errorMessage from './errorMessage-fr.json'
import general from './general-fr.json'
import biomarkConfigurationModal from './modals/biomarkConfigurationModal-fr.json'
import createDataSourceModal from './modals/createDataSourceModal-fr.json'
import createDataStoreModal from './modals/createDataStoreModal-fr.json'
import createWorkspaceModal from './modals/createWorkspaceModal-fr.json'
import delConfigurationModal from './modals/delConfigurationModal-fr.json'
import regulatorySubmissionConfigurationModal from './modals/regulatorySubmissionConfigurationModal-fr.json'
import auditTrailListPage from './pages/auditTrailListPage-fr.json'
import dataSourceDetailsPage from './pages/dataSourceDetailsPage-fr.json'
import dataSourcesListPage from './pages/dataSourcesListPage-fr.json'
import dataStoreDetailPage from './pages/dataStoreDetailsPage-fr.json'
import dataStoresListPage from './pages/dataStoresListPage-fr.json'
import forgotPasswordPage from './pages/forgotPasswordPage-fr.json'
import inviteCompleteProfile from './pages/inviteCompleteProfile-fr.json'
import literatureReviewDetailsPage from './pages/literatureReviewDetailsPage-fr.json'
import literatureReviewListPage from './pages/literatureReviewListPage-fr.json'
import literatureReviewSearchDetailsPage from './pages/literatureReviewSearchDetailsPage-fr.json'
import literatureReviewSearchDocumentDetailsPage from './pages/literatureReviewSearchDocumentDetailsPage-fr.json'
import loginPage from './pages/loginPage-fr.json'
import ofrListPage from './pages/ofrListPage-fr.json'
import searchPage from './pages/searchPage-fr.json'
import settingsPage from './pages/settingsPage-fr.json'
import smartFunctionDetailsPage from './pages/smartFunctionDetailsPage-fr.json'
import userDetailsPage from './pages/userDetailsPage-fr.json'
import userListPage from './pages/userListPage-fr.json'
import workspaceDetailsPage from './pages/workspaceDetailsPage-fr.json'
import workspaceListPage from './pages/workspaceListPage-fr.json'

const translationsFR = {
  ...component,
  ...general,
  auditTrailListPage,
  biomarkConfigurationModal,
  createDataSourceModal,
  createDataStoreModal,
  createWorkspaceModal,
  dataSourceDetailsPage,
  dataSourcesListPage,
  dataStoreDetailPage,
  dataStoresListPage,
  delConfigurationModal,
  errorMessage,
  forgotPasswordPage,
  inviteCompleteProfile,
  literatureReviewDetailsPage,
  literatureReviewListPage,
  literatureReviewSearchDetailsPage,
  literatureReviewSearchDocumentDetailsPage,
  loginPage,
  regulatorySubmissionConfigurationModal,
  ofrListPage,
  searchPage,
  settingsPage,
  smartFunctionDetailsPage,
  userDetailsPage,
  userListPage,
  workspaceDetailsPage,
  workspaceListPage,
}

export default translationsFR
