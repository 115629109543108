import { CloudDownloadOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ModalBase, SelectStore } from '../../../../../components'
import { useWorkspaceDetails } from '../../../../../hooks'
import notificationService from '../../../../../services/notificationService'
import workSpaceService from '../../../../../services/workspaceService'
import WorkspaceSettingsFooter from '../footer/WorkspaceSettingsFooter'

const WorkspaceDetailsAddStoreModal = () => {
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { workspaceId } = useParams()

  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const handleOk = () => {
    setConfirmLoading(true)
  }

  const handleCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const handleConfirm = useCallback(() => {
    setOpen(false)

    const selectedDataStore = form.getFieldValue('selectedDataStore')
    if (selectedWorkspace && selectedDataStore) {
      workSpaceService
        .updateWorkspaceDetails({
          workspaceId: selectedWorkspace.id,
          dataStores: [selectedDataStore],
        })
        .then(() => {
          refreshWorkspaceDetails()
          setConfirmLoading(false)
          form.resetFields()
          notificationService.notificationSuccess(
            t(
              'workspaceDetailsPage.workspaceStorePanel.modal.addDataStoreSuccess'
            )
          )
        })
        .catch(() => {
          setConfirmLoading(false)
          form.resetFields()
        })
    }
  }, [setOpen, selectedWorkspace, form, refreshWorkspaceDetails])

  return (
    <>
      <Button
        icon={<CloudDownloadOutlined />}
        onClick={() => setOpen(true)}
        type="primary"
      >
        {t('workspaceDetailsPage.workspaceStorePanel.addButton')}
      </Button>
      <ModalBase
        key="main"
        title={t('workspaceDetailsPage.workspaceStorePanel.modal.title')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        footer={
          <WorkspaceSettingsFooter
            handleCancel={handleCancel}
            handleSubmit={handleConfirm}
            icon={<CloudDownloadOutlined />}
            confirmButtonText={t(
              'workspaceDetailsPage.workspaceStorePanel.addButton'
            )}
          />
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="selectedDataStore"
            label={t(
              'workspaceDetailsPage.workspaceStorePanel.modal.subHeader' || ''
            )}
            extra={t(
              'workspaceDetailsPage.workspaceStorePanel.modal.infoBox' || ''
            )}
          >
            <SelectStore
              placeholder={
                t(
                  'workspaceDetailsPage.workspaceStorePanel.modal.placeholder'
                ) || ''
              }
            />
          </Form.Item>
        </Form>
      </ModalBase>
    </>
  )
}

export default WorkspaceDetailsAddStoreModal
