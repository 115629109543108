import { LinkOutlined } from '@ant-design/icons'
import { Button, Tooltip, Tree } from 'antd'
import { DataNode } from 'antd/es/tree'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import {
  SectionLoadingState,
  SimpleSwitch,
  SimpleSwitchContainer,
  Spacer,
} from '../../../../components'
import {
  NO_DATA_PLACEHOLDER,
  missingReferenceCategories,
} from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import useWorkspaceDetails from '../../../../hooks/useWorkspaceDetails'
import notificationService from '../../../../services/notificationService'
import workSpaceService from '../../../../services/workspaceService'
import {
  MissingReference,
  RefAIRunResult,
  WorkspaceDetails,
  setSelectedWorkspace,
} from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const MissingReferenceLineContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 24px;
  margin-bottom: 8px;
`

const MissingReferenceContainer = styled.div`
  color: ${(props) => props.theme.token.colorErrorText};
  font-weight: 600;
  min-width: 100px;
`

const DocumentLineContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`
const DocumentLinkContainer = styled.div`
  min-width: 32px;
`

interface RefAISmartFunctionMissingReferencesPanelProps {
  isIgnoredReference?: boolean
  search?: string
}

const RefAISmartFunctionMissingReferencesPanel = ({
  isIgnoredReference,
  search,
}: RefAISmartFunctionMissingReferencesPanelProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const dispatch = useDispatch()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [inclusionSwitchLoading, setInclusionSwitchLoading] = useState(false)

  const handleIncludeExclude = useCallback(
    (value: boolean, missingReference: string) => {
      setInclusionSwitchLoading(true)
      if (workspaceId) {
        workSpaceService
          .includeExcludeMissingReference({
            workspaceId,
            workspaceConfig: selectedWorkspace?.config,
            missingReference,
          })
          .then((response: WorkspaceDetails) => {
            setInclusionSwitchLoading(false)
            notificationService.notificationSuccess(
              t('smartFunctionDetailsPage.saveWorkspaceSuccessful') || ''
            )
            dispatch(setSelectedWorkspace(response))
          })
          .catch((error: AxiosError | Error) => {
            console.error('axios fetch error', error)
            setInclusionSwitchLoading(false)
          })
      }
    },
    [workspaceId, selectedWorkspace?.config, dispatch]
  )

  const getMissingReferenceKey = useMemo(
    () => workSpaceService.getRefAIMissingReferenceKey,
    []
  )

  const isMissingReferenceExcluded = useCallback(
    (missingReference: MissingReference) =>
      selectedWorkspace?.config?.excludedMissingReference?.includes(
        getMissingReferenceKey(missingReference)
      ),
    [selectedWorkspace, getMissingReferenceKey]
  )

  const getReferByIdKey = useMemo(() => workSpaceService.getReferByIdKey, [])

  const formattedData: DataNode[] = useMemo(() => {
    const refAIResult = selectedAlgorithmRun?.runResult as RefAIRunResult
    const formattedData = refAIResult?.missingReferences
      .filter((missingReference) => {
        const missingReferenceKey = getMissingReferenceKey(missingReference)
        const missingReferenceIsIgnored =
          selectedWorkspace?.config?.excludedMissingReference?.includes(
            missingReferenceKey
          )
        return (
          !isIgnoredReference ||
          (isIgnoredReference && missingReferenceIsIgnored)
        )
      })
      .filter(
        (missingReference) =>
          missingReference.category ===
            missingReferenceCategories.EXTERNAL_REFERENCES ||
          missingReference.category ===
            missingReferenceCategories.INTERNAL_REFERENCES
      )
      .filter((missingReference) =>
        !!search
          ? getMissingReferenceKey(missingReference).includes(search || '')
          : true
      )
      .sort((a, b) => b.referBy.length - a.referBy.length)
      .map((missingReference, index) => {
        const missingReferenceIndex = index
        const documents: DataNode[] = missingReference.referBy.map(
          (document, index) => ({
            key: `0-${missingReferenceIndex}-${index}`,
            title: (
              <DocumentLineContainer>
                {getReferByIdKey(document)
                  ? `${getReferByIdKey(document)} - `
                  : ''}
                {document.title || NO_DATA_PLACEHOLDER}
                <Spacer />
                <Tooltip
                  title={t('smartFunctionDetailsPage.linkTooltip')}
                  placement="left"
                  align={{ offset: [-24, 0] }}
                >
                  <DocumentLinkContainer>
                    <Button
                      type="default"
                      icon={<LinkOutlined />}
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </DocumentLinkContainer>
                </Tooltip>
              </DocumentLineContainer>
            ),
            displayText: document.title,
            isDocument: true,
            isMissingReference: false,
            className: 'document-tree-node',
            selectable: false,
          })
        )

        const formattedMissingReference: DataNode = {
          key: `0-${missingReferenceIndex}`,
          title: (
            <MissingReferenceLineContainer>
              <MissingReferenceContainer>
                {getMissingReferenceKey(missingReference)}
              </MissingReferenceContainer>
              <Spacer />
              <SimpleSwitchContainer>
                <div>
                  {isMissingReferenceExcluded(missingReference)
                    ? t('Excluded')
                    : t('Included')}
                </div>
                <SimpleSwitch
                  checked={!isMissingReferenceExcluded(missingReference)}
                  onClick={(value: boolean) =>
                    handleIncludeExclude(
                      value,
                      getMissingReferenceKey(missingReference)
                    )
                  }
                />
              </SimpleSwitchContainer>
            </MissingReferenceLineContainer>
          ),
          children: documents,
          className: isMissingReferenceExcluded(missingReference)
            ? 'missing-reference-tree-node excluded-missing-reference'
            : 'missing-reference-tree-node included-missing-reference',
          selectable: false,
        }

        return formattedMissingReference
      })
    return formattedData || []
  }, [
    selectedAlgorithmRun?.runResult,
    handleIncludeExclude,
    selectedWorkspace?.config?.excludedMissingReference,
    isIgnoredReference,
    search,
    isMissingReferenceExcluded,
    getMissingReferenceKey,
    getReferByIdKey,
  ])

  return loading || inclusionSwitchLoading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <Tree treeData={formattedData} showLine />
    </SmartFunctionListPanelLayout>
  )
}

export default RefAISmartFunctionMissingReferencesPanel
