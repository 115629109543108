import { NavigateFunction } from 'react-router-dom'
import { urlList } from '../constants/urlConstant'
import {
  AlgorithmRun,
  BiomarkConfigValues,
  RegulatorySubmissionConfigValues
} from '../store/reducers/workspaceReducer'
import apiService from './apiService'

const baseUrl = urlList.WORKSPACES

interface RunAlgorithmProps {
  id: string
  navigate?: NavigateFunction
  config?: BiomarkConfigValues | RegulatorySubmissionConfigValues
}

interface CancelRunProps {
  id: string
  algorithmRunId: string
  navigate: NavigateFunction
}

const runAlgorithm = async ({ id, navigate, config }: RunAlgorithmProps) => {
  return apiService
    .saveItem(`${baseUrl}/${id}/algorithmRuns`, navigate, {
      config: config,
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

const cancelRun = async ({ id, algorithmRunId, navigate }: CancelRunProps) => {
  return apiService
    .deleteItem(
      `${baseUrl}/${id}/algorithmRuns/${algorithmRunId}/cancel`,
      navigate
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

interface getWorkspaceAlgorithmRunProps {
  workspaceId: string
  algorithmRunId: string
}

const getWorkspaceAlgorithmRun = async ({
  workspaceId,
  algorithmRunId,
}: getWorkspaceAlgorithmRunProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}`)
    .then((response: AlgorithmRun) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportRunProps {
  workspaceId: string
  algorithmRunId: string
}

const exportRun = async ({ workspaceId, algorithmRunId }: ExportRunProps) => {
  return apiService
    .fetchItems(
      `${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}/result/csv`
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const smartFunctionService = {
  runAlgorithm,
  cancelRun,
  exportRun,
  getWorkspaceAlgorithmRun,
}

export default smartFunctionService
