import { Form, Input, Radio, RadioChangeEvent, Space } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import {
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
  SelectParticipant,
} from '../../../components'
import { privacyTypes } from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'

interface WorkspaceSettingsInfoPanelProps {
  form: FormInstance
}
const WorkspaceSettingsInfoPanel = ({
  form,
}: WorkspaceSettingsInfoPanelProps) => {
  const { selectedWorkspace } = useWorkspaceDetails({
    preventFetch: true,
  })
  const [isPrivate, setIsPrivate] = useState(
    selectedWorkspace?.visibility === privacyTypes.PRIVATE
  )
  const [isSelectDisabled, setIsSelectDisabled] = useState(
    selectedWorkspace?.visibility === privacyTypes.ORGANIZATION || false
  )

  const [initialParticipants, setInitialParticipants] = useState<string[]>(
    selectedWorkspace?.participants?.map((participant) => participant.id) || []
  )

  const handleRadioChange = useCallback((e: RadioChangeEvent) => {
    e.preventDefault()
    setIsPrivate(e.target.value === privacyTypes.PRIVATE)
    setIsSelectDisabled(e.target.value === privacyTypes.ORGANIZATION)
  }, [])

  useEffect(() => {
    const setParticipantsAndValues = () => {
      if (!selectedWorkspace) return

      const currentVisibility = form.getFieldValue('visibility')
      const isPrivate = currentVisibility === privacyTypes.PRIVATE

      setIsPrivate(isPrivate)
      setIsSelectDisabled(!isPrivate)

      const participants = isPrivate
        ? selectedWorkspace.participants?.map(
            (participant) => participant.id
          ) || []
        : []

      setInitialParticipants(participants)
      form.setFieldValue('participants', participants)
    }

    setParticipantsAndValues()
  }, [selectedWorkspace, form])

  return (
    <MultiColumnFormContentContainer>
      <MultiColumnFormColumnContainer>
        <Form.Item
          label={t('Title')}
          required
          name="title"
          rules={[
            { required: true, message: t('errorMessage.isRequired') || '' },
          ]}
        >
          <Input width={'100%'} placeholder={'Title'} />
        </Form.Item>
        <Form.Item
          label={t('Description')}
          name="description"
        >
          <Input width={'100%'} placeholder={'Description'} />
        </Form.Item>
        {selectedWorkspace?.visibility && (
          <Form.Item
            label={t('Privacy Settings')}
            name="visibility"
            rules={[
              {
                required: true,
                message: t('errorMessage.isRequired') || '',
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              defaultValue={selectedWorkspace?.visibility}
              onChange={handleRadioChange}
            >
              <Space direction="vertical">
                <Radio value={privacyTypes.PRIVATE}>
                  {t(
                    'createWorkspaceModal.privacyStep.privateOption'
                  )}
                </Radio>
                <Radio
                  value={privacyTypes.ORGANIZATION}
                  disabled={
                    selectedWorkspace?.dataSource.visibility ===
                    privacyTypes.PRIVATE
                  }
                >
                  {t(
                    'createWorkspaceModal.privacyStep.organizationalOption'
                  )}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item
          label={t(
            'createWorkspaceModal.privacyStep.participantSelectHeader'
          )}
          name="participants"
          rules={[
            {
              required: isPrivate,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={
            t(
              'createWorkspaceModal.privacyStep.extraInputPlaceholder'
            ) || ''
          }
        >
          <SelectParticipant
            disabled={isSelectDisabled}
            initialParticipants={initialParticipants}
            allParticipants={selectedWorkspace?.dataSource.participants || []}
          />
        </Form.Item>
      </MultiColumnFormColumnContainer>
      <MultiColumnFormColumnContainer></MultiColumnFormColumnContainer>
    </MultiColumnFormContentContainer>
  )
}

export default WorkspaceSettingsInfoPanel
