import { InboxOutlined } from '@ant-design/icons'
import { Input, theme } from 'antd'
import { t } from 'i18next'
import { ReactNode, useMemo } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { AllowClearIcon } from '../../../components'
import BackButton from '../../../components/BackButton'
import BoldText from '../../../components/display/BoldText'
import {
  NO_DATA_PLACEHOLDER,
  algorithmRunStatuses,
  dateFormat,
  workspaceTypes,
} from '../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import SmartFunctionActionDropdown from './SmartFunctionActionDropdown'

const { Search } = Input

const SubHeader = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 12px;
  gap: 16px;
`

const MainSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const MainSubHeaderLine = styled.div`
  display: flex;
  flex: 1;
  padding: 8px;
  gap: 16px;
  color: ${(props) => props.theme.token.colorText};
`

const MainSubHeaderCell = styled.div`
  flex: 50%;
`

const MainSubHeaderCellContainer = styled.div`
  flex: 50%;
  display: flex;
`

const MainSubHeaderCellSection = styled.div`
  flex: auto;
  color: ${(props) => props.theme.token.colorText};
`

const LabelBlueContainer = styled.div`
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--blue-3, #91caff);
  background: var(--blue-1, #e6f4ff);
  width: fit-content;
  color: black;
`

const TotalCellContainer = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  gap: 8px;
`
const { useToken } = theme

interface SmartFunctionHeaderLayoutProps {
  backButtonUrl: string
  totalFound: number
  onSearchAction?: (value: string) => void
  onSearchChange?: (event: any) => void
  search?: ReactNode
  children?: ReactNode
}

const SmartFunctionHeader = ({
  backButtonUrl,
  children,
  onSearchAction,
  onSearchChange,
  search,
  totalFound,
}: SmartFunctionHeaderLayoutProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const { token } = useToken()
  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
    preventFetch: true,
  })
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const algorithmRunIndex = useMemo(() => {
    return selectedAlgorithmRun
      ? (selectedWorkspace?.algorithmRuns
          ?.map((run) => run.id)
          .indexOf(selectedAlgorithmRun.id) || 0) + 1
      : 0
  }, [selectedAlgorithmRun, selectedWorkspace])

  return (
    <SubHeader>
      <BackButton
        url={backButtonUrl}
        tooltipMessage={t('smartFunctionDetailsPage.backButtonTooltip')}
      />
      <MainSubHeader>
        <MainSubHeaderLine>
          <MainSubHeaderCell>
            <BoldText>
              {selectedWorkspace?.algorithmName || NO_DATA_PLACEHOLDER}
            </BoldText>
          </MainSubHeaderCell>
          <MainSubHeaderCellContainer>
            <MainSubHeaderCellSection>
              {selectedAlgorithmRun?.actionDate ? (
                <Moment
                  local
                  date={selectedAlgorithmRun?.actionDate}
                  format={dateFormat.PRIMARY}
                />
              ) : (
                NO_DATA_PLACEHOLDER
              )}
            </MainSubHeaderCellSection>
            <MainSubHeaderCellSection>
              {t('smartFunctionDetailsPage.runCount', {
                count: algorithmRunIndex || 0,
              })}
            </MainSubHeaderCellSection>
            <MainSubHeaderCellSection>
              {selectedAlgorithmRun?.runStatus
                ? t(
                    `workspaceDetailsPage.status.${selectedAlgorithmRun?.runStatus}`
                  )
                : NO_DATA_PLACEHOLDER}
            </MainSubHeaderCellSection>
            <SmartFunctionActionDropdown
              key={selectedAlgorithmRun?.id}
              algorithmRun={selectedAlgorithmRun}
            />
          </MainSubHeaderCellContainer>
        </MainSubHeaderLine>
        <MainSubHeaderLine>
          <MainSubHeaderCell>
            <LabelBlueContainer>
              {selectedWorkspace?.dataSource.title || NO_DATA_PLACEHOLDER}
            </LabelBlueContainer>
          </MainSubHeaderCell>
          <MainSubHeaderCell>
            {selectedAlgorithmRun?.actionBy?.displayName || NO_DATA_PLACEHOLDER}
          </MainSubHeaderCell>
        </MainSubHeaderLine>
        {selectedAlgorithmRun?.runStatus === algorithmRunStatuses.COMPLETE &&
          selectedWorkspace?.typeCode !== workspaceTypes.DEL && (
            <MainSubHeaderLine>
              {children}
              <TotalCellContainer>
                <InboxOutlined />
                <div>
                  {totalFound} {t('Found')}
                </div>
              </TotalCellContainer>
              <Search
                placeholder={t('mainHeader.searchPlaceholder') || ''}
                allowClear={{
                  clearIcon: <AllowClearIcon />,
                }}
                onSearch={onSearchAction}
                onChange={onSearchChange}
                style={{ flex: 1, backgroundColor: token.colorBgBase }}
              />
            </MainSubHeaderLine>
          )}
      </MainSubHeader>
    </SubHeader>
  )
}

export default SmartFunctionHeader
