import { useRef, MouseEvent, useState } from 'react'
import formImage from '../../assets/images/Form_for_testing_OFR.jpeg'
import { OfrFormContainer } from '../../components'

interface AnnotationInfo {
  name: string
  type: string
  x: number
  y: number
}

const TestOfrConfigPage = () => {
  const imageRef = useRef<HTMLImageElement>(null)
  const [formScrollLeft, setFormScrollLeft] = useState(0)
  const scrollRef = useRef(null)

  const handleImageClick = (e: MouseEvent<HTMLImageElement>) => {
    const imageData = imageRef.current?.getBoundingClientRect()
    //Keep console.log for testing
    console.log('Image info', imageData, e)
    console.log('scroll left', formScrollLeft)

    const annotationInfo: AnnotationInfo = {
      name: 'test',
      type: 'test',
      x: (e.clientX ?? 0) + formScrollLeft,
      y: e.clientY ?? 0,
    }

    console.log('Mark info', annotationInfo)
  }

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft } = scrollRef.current
      setFormScrollLeft(scrollLeft)
    }
  }

  return (
    <OfrFormContainer onScroll={handleScroll} ref={scrollRef}>
      Scroll left {formScrollLeft}
      <img
        src={formImage}
        alt="form"
        ref={imageRef}
        onClick={handleImageClick}
      />
    </OfrFormContainer>
  )
}

export default TestOfrConfigPage
