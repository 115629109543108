import { useMemo, useCallback } from 'react'
import SmartFunctionHeader from '../../components/SmartFunctionHeader'
import { routePaths } from '../../../RootPage'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../../hooks'
import { useParams } from 'react-router-dom'
import { OfrRunResult } from '../../../../store/reducers/workspaceReducer'
import SelectQualityReviewDeviationFilterChoice from './SelectQualityReviewDeviationFilterChoice'
import { QualityReviewFilterType } from '../QualityReviewFunctionDetailsPage'

interface QualityReviewSmartFunctionDetailsPageHeaderProps {
  handleFilterChange: (value: QualityReviewFilterType | undefined) => void
  setSearch: (search: string) => void
  search: string
}

const QualityReviewSmartFunctionDetailsPageHeader = ({
  handleFilterChange,
  setSearch,
  search,
}: QualityReviewSmartFunctionDetailsPageHeaderProps) => {
  const { workspaceId, algorithmRunId } = useParams()

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })
  const smartFunctionResult = useMemo(
    () => selectedAlgorithmRun?.runResult as OfrRunResult,
    [selectedAlgorithmRun?.runResult]
  )
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const searchedItems = useMemo(
    () =>
      smartFunctionResult?.blocks.filter((item) =>
        !!search ? item.ofrBlockName.includes(search || '') : true
      ) || [],
    [search, smartFunctionResult]
  )

  const backButtonUrl = useMemo(
    () =>
      `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`,
    [selectedWorkspace?.id]
  )

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  return (
    <SmartFunctionHeader
      backButtonUrl={backButtonUrl}
      totalFound={searchedItems.length || 0}
      onSearchAction={handleSearch}
      onSearchChange={(event) => handleSearch(event.target.value)}
    >
      <SelectQualityReviewDeviationFilterChoice onChange={handleFilterChange} />
    </SmartFunctionHeader>
  )
}

export default QualityReviewSmartFunctionDetailsPageHeader
