import { styled } from 'styled-components'

const OfrFormContainer = styled.div`
  display: block;
  justify-content: center;
  margin: auto 16px auto 16px;
  padding-top: 16px;
  min-height: 36px;
  overflow: auto;
`

export default OfrFormContainer
