import 'antd/dist/reset.css'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import {
  permissionActions,
  permissionNames,
  permissionValues,
} from './constants/permissionConstant'
import AuditTrailListPage from './pages/AuditTrailListPage/AuditTrailListPage'
import DataSourceDetailsPage from './pages/DataSourcesDetailsPage/DataSourcesDetailsPage'
import DataSourcesListPage from './pages/DataSourcesListPage/DataSourcesListPage'
import DataStoreDetailsPage from './pages/DataStoreDetailsPage/DataStoresDetailsPage'
import DataStoresListPage from './pages/DataStoreListPage/DataStoreListPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import InviteCompleteProfilePage from './pages/InviteCompleteProfilePage/InviteCompleteProfilePage'
import LiteratureReviewDetailsPage from './pages/LiteratureReviewDetailsPage/LiteratureReviewDetailsPage'
import LiteratureReviewListPage from './pages/LiteratureReviewListPage/LiteratureReviewListPage'
import LiteratureReviewSearchDetailsPage from './pages/LiteratureReviewSearchDetailsPage/LiteratureReviewSearchDetailsPage'
import LiteratureReviewSearchDocumentDetailsPage from './pages/LiteratureReviewSearchDocumentDetailsPage/LiteratureReviewSearchDocumentDetailsPage'
import LoginPage from './pages/LoginPage/LoginPage'
import OfrFormDetailsPage from './pages/OfrFormDetailsPage/OfrFormDetailsPage'
import OfrFormListPage from './pages/OfrFormListPage/OfrFormListPage'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import RootPage from './pages/RootPage'
import SearchDocumentDetailsPage from './pages/SearchDocumentDetailsPage/SearchDocumentDetailsPage'
import SearchPage from './pages/SearchPage/SearchPage'
import SettingsPage from './pages/SettingsPage/SettingsPage'
import BiomarkSmartFunctionDetailsPage from './pages/SmartFunctions/BiomarkSmartFunctionDetailsPage/BiomarkSmartFunctionDetailsPage'
import DelSmartFunctionDetailsPage from './pages/SmartFunctions/DelSmartFunctionDetailsPage/DelSmartFunctionDetailsPage'
import QualityReviewFunctionDetailsPage from './pages/SmartFunctions/QualityReviewFonctionDetailsPage/QualityReviewFunctionDetailsPage'
import RefAISmartFunctionDetailsPage from './pages/SmartFunctions/RefAISmartFunctionDetailsPage/RefAISmartFunctionDetailsPage'
import RegulatorySubmissionSmartFunctionDetailsPage from './pages/SmartFunctions/RegulatorySubmissionFunctionDetailsPage/RegulatorySubmissionSmartFunctionDetailsPage'
import SsoManagementPage from './pages/SsoManagementPage/SsoManagementPage'
import TestIFramePage from './pages/TestIFramePage/TestIFramePage'
import TestOfrConfigPage from './pages/TestOfrConfigPage/TestOfrConfigPage'
import UserDetailsPage from './pages/UserDetailsPage/UserDetailsPage'
import UserListPage from './pages/UserListPage/UserListPage'
import WorkspaceDetailsPage from './pages/WorkspaceDetailsPage/WorkspaceDetailsPage'
import WorkspaceListPage from './pages/WorkspaceListPage/WorkspaceListPage'
import roleService from './services/roleService'
import { RootState } from './store'

function App() {
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  return (
    <RootPage>
      <Routes>
        <Route path="" element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="loginSso" element={<SsoManagementPage />} />
        <Route
          path="login/displayUnauthorizeNotification"
          element={<LoginPage />}
        />
        <Route path="forgotPassword" element={<ForgotPasswordPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="workspaceList" element={<WorkspaceListPage />} />
        <Route
          path="workspaceDetails/:workspaceId"
          element={<WorkspaceDetailsPage />}
        />
        <Route
          path="workspaceDetails/:workspaceId/:panelType/:tab"
          element={<WorkspaceDetailsPage />}
        />
        <Route
          path="workspaceSmartFunctionDetails_REFAI/:workspaceId/:algorithmRunId"
          element={<RefAISmartFunctionDetailsPage />}
        />
        <Route
          path="workspaceSmartFunctionDetails_REFAI/:workspaceId/:algorithmRunId/:referenceType"
          element={<RefAISmartFunctionDetailsPage />}
        />
        {/* Single workspace section: Begin */}
        <Route
          path="singleWorkspaceDetails/:workspaceId"
          element={<WorkspaceDetailsPage singleWorkspace />}
        />
        <Route
          path="singleWorkspaceDetails/:workspaceId/:panelType/:tab"
          element={<WorkspaceDetailsPage singleWorkspace />}
        />
        <Route
          path="singleWorkspaceSmartFunctionDetails_REFAI/:workspaceId/:algorithmRunId"
          element={<RefAISmartFunctionDetailsPage singleWorkspace />}
        />
        <Route
          path="singleWorkspaceSmartFunctionDetails_REFAI/:workspaceId/:algorithmRunId/:referenceType"
          element={<RefAISmartFunctionDetailsPage singleWorkspace />}
        />
        {/* Single workspace section: End */}
        <Route
          path="/workspaceSmartFunctionDetails_BIOMARK/:workspaceId/:algorithmRunId"
          element={<BiomarkSmartFunctionDetailsPage />}
        />
        <Route
          path="/workspaceSmartFunctionDetails_REGSUB/:workspaceId/:algorithmRunId"
          element={<RegulatorySubmissionSmartFunctionDetailsPage />}
        />
        <Route
          path="/workspaceSmartFunctionDetails_DEL/:workspaceId/:algorithmRunId"
          element={<DelSmartFunctionDetailsPage />}
        />
        <Route
          path="/workspaceSmartFunctionDetails_OFR_QR/:workspaceId/:algorithmRunId"
          element={<QualityReviewFunctionDetailsPage />}
        />
        <Route
          path="searchDocumentDetails/:documentId"
          element={<SearchDocumentDetailsPage />}
        />
        <Route
          path="literatureReviewSearch/:literatureReviewId/:searchId"
          element={<LiteratureReviewSearchDetailsPage />}
        />
        <Route
          path="literatureReviewDocument/:literatureReviewId/:searchId/:documentId"
          element={<LiteratureReviewSearchDocumentDetailsPage />}
        />
        <Route
          path="literatureReviewList"
          element={<LiteratureReviewListPage />}
        />
        <Route
          path="literatureReviewDetails/:literatureReviewId"
          element={<LiteratureReviewDetailsPage />}
        />
        <Route
          path="literatureReviewDetails/:literatureReviewId/:panelType/:tab"
          element={<LiteratureReviewDetailsPage />}
        />
        {currentUser &&
          roleService.hasPermission(
            currentUser,
            permissionNames.DATA_SOURCES,
            permissionActions.ALL
          ) && (
            <>
              <Route path="dataSourceList" element={<DataSourcesListPage />} />
              <Route
                path="dataSourceDetails/:dataSourceId"
                element={<DataSourceDetailsPage />}
              />
              <Route path="dataStoreList" element={<DataStoresListPage />} />
              <Route
                path="dataStoreDetails/:dataStoreId"
                element={<DataStoreDetailsPage />}
              />
            </>
          )}
        <Route path="userList/:filter?" element={<UserListPage />} />
        <Route path="userDetails/:userId" element={<UserDetailsPage />} />
        {currentUser &&
          roleService.hasPermission(
            currentUser,
            permissionNames.AUDIT_TRAILS,
            permissionActions.READ,
            permissionValues.ALL
          ) && <Route path="auditTrailList" element={<AuditTrailListPage />} />}
        <Route path="invite/complete" element={<InviteCompleteProfilePage />} />
        <Route path="ofrFormList" element={<OfrFormListPage />} />
        <Route path="ofrFormDetails" element={<OfrFormDetailsPage />} />
        <Route path="testPage" element={<TestIFramePage />} />
        <Route path="testOfrPage" element={<TestOfrConfigPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </RootPage>
  )
}

export default withTranslation()(App)
