import { DeleteOutlined } from '@ant-design/icons'; // Import the necessary Ant Design icon
import { Button, Tooltip } from 'antd'; // Assuming you are using Ant Design components
import { TooltipPlacement } from 'antd/lib/tooltip'
import React from 'react'

interface DeleteIconButtonProps {
  tooltipPlacement?: TooltipPlacement
  tooltipTitle: string
  icon?: React.ReactNode
  handleDeleteClick: any
}

const DeleteIconButton = ({
  tooltipPlacement,
  tooltipTitle,
  icon,
  handleDeleteClick,
}: DeleteIconButtonProps) => {
  return (
    <Tooltip
      placement={tooltipPlacement ? tooltipPlacement : 'left'}
      title={tooltipTitle}
      align={{ offset: [-24, 0] }}
    >
      <Button
        danger
        icon={icon ? icon : <DeleteOutlined />}
        onClick={handleDeleteClick}
      />
    </Tooltip>
  )
}

export default DeleteIconButton
