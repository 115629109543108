import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  TableBoldColumnContainer,
} from '../../../components'
import { NO_DATA_PLACEHOLDER, dateFormat } from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import notificationService from '../../../services/notificationService'
import workSpaceService from '../../../services/workspaceService'
import { DataStore } from '../../../store/reducers/dataStoreReducer'

interface DataStoreExtended extends DataStore {
  key: string
  destination: string
}

const WorkspaceSettingsStorePanel = () => {
  const { workspaceId } = useParams()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const [confirmRemoveModalOpen, setConfirmRemoveModalOpen] = useState(false)
  const [removeId, setRemoveId] = useState<string | undefined>(undefined)
  const [removeLoading, setRemoveLoading] = useState(false)

  const formattedData: DataStoreExtended[] =
    useMemo(
      () =>
        selectedWorkspace?.dataStores &&
        typeof selectedWorkspace?.dataStores[0] !== 'string'
          ? selectedWorkspace?.dataStores?.map((dataStore: DataStore) => ({
              ...dataStore,
              key: dataStore.id,
              destination: dataStore.typeName,
            }))
          : [],

      [selectedWorkspace?.dataStores]
    ) || []

  const handleCancelRemove = (event: MouseEvent) => {
    setConfirmRemoveModalOpen(false)
  }

  const handleConfirmRemove = (event: MouseEvent) => {
    if (removeId) {
      setRemoveLoading(true)
      workSpaceService
        .updateWorkspaceDetails({
          workspaceId: selectedWorkspace?.id || '',
          dataStores: [],
        })
        .then(() => {
          notificationService.notificationSuccess(
            t(
              'workspaceDetailsPage.workspaceStorePanel.modal.removeDataStoreSuccess'
            )
          )
          refreshWorkspaceDetails()
          setRemoveLoading(false)
        })
        .catch(() => {
          setRemoveLoading(false)
        })
    }
    setConfirmRemoveModalOpen(false)
    setRemoveId(undefined)
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setRemoveId(id)
    setConfirmRemoveModalOpen(true)
  }, [])

  const columns: ColumnsType<DataStore> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '60%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Destination'),
      dataIndex: 'destination',
      key: 'destination',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Creation Date'),
      key: 'createDate',
      dataIndex: 'createdDate',
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id }) => (
        <DeleteIconButton
          tooltipTitle={
            t(
              'workspaceDetailsPage.workspaceStorePanel.deleteDatastoreTooltip'
            ) || ''
          }
          handleDeleteClick={(event: MouseEvent) =>
            handleDeleteClick(event, id)
          }
        />
      ),
    },
  ]

  return (
    <>
      {formattedData && (
        <Table
          rowClassName="page-list-table-row clickable"
          columns={columns}
          dataSource={formattedData}
          pagination={false}
          scroll={{ y: 'calc(100vh - 170px)' }}
        />
      )}
      <ConfirmDeleteModal
        open={confirmRemoveModalOpen}
        onConfirm={handleConfirmRemove}
        onCancel={handleCancelRemove}
        loading={removeLoading}
        confirmButtonLabel={
          t('workspaceDetailsPage.workspaceStorePanel.removeModal.yesButton') ||
          ''
        }
      >
        {t('workspaceDetailsPage.workspaceStorePanel.removeModal.content')}
      </ConfirmDeleteModal>
    </>
  )
}

export default WorkspaceSettingsStorePanel
