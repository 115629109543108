import Table, { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { MouseEvent, useCallback, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  TableBoldColumnContainer,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  privacyTypes,
} from '../../constants/constants'
import { routePaths } from '../RootPage'

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0;
`

const InitialMessageContainer = styled.div`
  font-weight: 600;
  margin: auto;
`

const OfrFormListPage = () => {
  const navigate = useNavigate()

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const formattedData: [] = []

  const handleCancelDelete = (event: MouseEvent) => {
    event.stopPropagation()
    setConfirmDeleteModalOpen(false)
  }

  const handleConfirmDelete = (event: MouseEvent) => {
    event.stopPropagation()
    setConfirmDeleteModalOpen(false)
  }

  const handleClick = (id: string) => {
    navigate(`${routePaths.OFR_FORM_LIST}/${id}`)
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setConfirmDeleteModalOpen(true)
  }, [])

  const columns: ColumnsType<any> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '60%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },

    {
      title: t('Creation Date'),
      key: 'createdDate',
      dataIndex: 'createdDate',
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'typeName',
      key: 'typeName',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id, visibility }) => (
        <>
          {visibility !== privacyTypes.PUBLIC && (
            <DeleteIconButton
              tooltipTitle={t('ofrListPage.deleteAnnotationTemplateTooltip')}
              handleDeleteClick={(event: MouseEvent) =>
                handleDeleteClick(event, id)
              }
            />
          )}
        </>
      ),
    },
  ]

  const handleExportOfrList = useCallback(() => {
    // export function
  }, [])

  return (
    <>
      <ListPageLayout>
        <ListPageHeader
          title={t('ofrListPage.title', {
            count: 0,
          })}
          actions={
            <>
              <PageContentDropdown
                onExport={handleExportOfrList}
                disabled={formattedData.length === 0}
              />
            </>
          }
        />
        {formattedData.length === 0 ? (
          <FullPageContainer>
            <InitialMessageContainer>
              {t('ofrListPage.initialMessage')}
            </InitialMessageContainer>
          </FullPageContainer>
        ) : (
          <Table
            rowClassName="page-list-table-row clickable"
            columns={columns}
            dataSource={formattedData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 170px)' }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleClick(record.id)
                }, // click row
              }
            }}
          />
        )}
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          confirmButtonLabel={
            t('ofrListPage.confirmDeleteModal.yesButton') || ''
          }
        >
          {t('ofrListPage.confirmDeleteModal.content')}
        </ConfirmDeleteModal>
      </ListPageLayout>
    </>
  )
}

export default OfrFormListPage
