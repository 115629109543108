import { NavigateFunction } from 'react-router-dom'
import { privacyTypes, urlList } from '../constants/constants'
import i18n from '../i18n/i18n'
import {
  DataSource,
  DataSourceConfigType,
  EgnyteSecrets,
  SourceType,
} from '../store/reducers/dataSourceReducer'
import { PrivacyType, TranslationDictionary } from '../types/generalTypes'
import apiService from './apiService'

const baseUrl = urlList.DATA_SOURCES

const getDataSourceTitle = (source?: DataSource) => {
  if (!source) {
    return ''
  }
  const title = source.title
  if (typeof title === 'string') {
    return title
  } else {
    const languages = Object.keys(title)
    if (languages.length > 0) {
      return title[i18n.language]
    } else {
      return ''
    }
  }
}

export interface DataSourceTypeItem {
  id: string
  name: string
}

const getTypeList = (sources: DataSource[]): DataSourceTypeItem[] => {
  const mapSourceToTypeItem = (source: DataSource): DataSourceTypeItem => ({
    id: source.searchable
      ? source.typeCode
      : source.isInternal
      ? source.organizationName
      : source.typeName,
    name: source.searchable
      ? source.typeName
      : source.isInternal
      ? source.organizationName
      : source.typeName,
  })

  const typeArray = sources.map(mapSourceToTypeItem)

  const uniqueTypeList = typeArray.filter(
    (type, pos, self) =>
      !!type.id && self.findIndex((item) => item.id === type.id) === pos
  )

  uniqueTypeList.sort((a, b) => a.name.localeCompare(b.name))

  return uniqueTypeList
}

interface GetDataSourceDetailsProps {
  dataSourceId: string
}

const getDataSourceDetails = async ({
  dataSourceId,
}: GetDataSourceDetailsProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${dataSourceId}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface UpdateDataSourceDetailsProps {
  title: string | TranslationDictionary
  description: string
  visibility: PrivacyType
  participants: string[]
  contactName: string
  contactEmail: string
  dataSourceId: string
  navigate: NavigateFunction
}

const updateDataSourceDetails = async ({
  title,
  description,
  visibility,
  participants,
  contactName,
  contactEmail,
  dataSourceId,
  navigate,
}: UpdateDataSourceDetailsProps) => {
  const params = {
    title,
    description,
    visibility,
    participants: visibility === privacyTypes.PRIVATE ? participants : [],
    contactEmail,
    contactName,
  }

  return apiService
    .updateItem(`${baseUrl}/${dataSourceId}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios update error', error)
      throw error
    })
}

interface DeleteDataSourceProps {
  dataSourceId: string
  navigate: NavigateFunction
}

const deleteDataSource = async ({
  dataSourceId,
  navigate,
}: DeleteDataSourceProps) => {
  return apiService
    .deleteItem(`${baseUrl}/${dataSourceId}`, navigate)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios delete error', error)
      throw error
    })
}

interface CreateDataSourceProps {
  title: string | TranslationDictionary
  description: string
  sourceType: SourceType
  visibility: PrivacyType
  participants: string[]
  navigate: NavigateFunction
  config: DataSourceConfigType
  secrets: EgnyteSecrets
  contactEmail: string
  contactName: string
}

const createDataSource = async ({
  title,
  description,
  visibility,
  sourceType,
  participants,
  config,
  navigate,
  secrets,
  contactName,
  contactEmail,
}: CreateDataSourceProps) => {
  const params = {
    title,
    description,
    visibility,
    config,
    sourceType,
    participants: visibility === privacyTypes.PRIVATE ? participants : [],
    navigate,
    secrets,
    contactEmail,
    contactName,
  }

  return apiService
    .saveItem(`${baseUrl}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

const getDataSourceType = async () => {
  return apiService
    .fetchItems(urlList.DATA_SOURCE_TYPES)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const exportDataSourceList = async () => {
  return apiService
    .fetchItems(`${baseUrl}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportDataSourceDetailsProp {
  dataSourceId: string
}

const exportDataSourceDetails = async ({
  dataSourceId,
}: ExportDataSourceDetailsProp) => {
  return apiService
    .fetchItems(`${baseUrl}/${dataSourceId}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const dataSourceService = {
  createDataSource,
  deleteDataSource,
  exportDataSourceDetails,
  exportDataSourceList,
  getDataSourceDetails,
  getDataSourceTitle,
  getDataSourceType,
  getTypeList,
  updateDataSourceDetails,
}
export default dataSourceService
