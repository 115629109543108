import { useDispatch } from 'react-redux'
import { clearDataSourceState } from '../store/reducers/dataSourceReducer'
import { clearDataStoreState } from '../store/reducers/dataStoreReducer'
import { clearLiteratureReviewState } from '../store/reducers/literatureReviewReducer'
import { clearSearchResultState } from '../store/reducers/searchResultInfoReducer'
import { clearUserState } from '../store/reducers/userReducer'
import { clearWorkspaceState } from '../store/reducers/workspaceReducer'
import { clearAuditTrailsState } from '../store/reducers/auditTrailReducer'

const useClearStore = () => {
  const dispatch = useDispatch()
  const clearStore = () => {
    dispatch(clearSearchResultState())
    dispatch(clearDataSourceState())
    dispatch(clearLiteratureReviewState())
    dispatch(clearUserState())
    dispatch(clearDataStoreState())
    dispatch(clearWorkspaceState())
    dispatch(clearAuditTrailsState())
  }
  return clearStore
}

export default useClearStore
