import { Form, FormInstance, Radio, Space } from 'antd'
import { t } from 'i18next'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import workSpaceService from '../../../../services/workspaceService'
import { RootState } from '../../../../store'
import {
  WorkspaceType,
  setWorkspaceTypeList,
} from '../../../../store/reducers/workspaceReducer'

interface CreateWorkspaceModalTypeStepProps {
  form: FormInstance
}

const CreateWorkspaceModalTypeStep = memo(
  ({ form }: CreateWorkspaceModalTypeStepProps) => {
    const dispatch = useDispatch()
    const workspaceTypeList = useSelector(
      (state: RootState) => state.workspace.workspaceTypeList
    )

    useEffect(() => {
      workSpaceService.getWorkspaceType().then((response: WorkspaceType[]) => {
        dispatch(setWorkspaceTypeList(response))
      })
    }, [dispatch])

    return (
      <>
        <Form.Item
          label={t('createWorkspaceModal.typeStep.subHeader')}
          name="type"
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            <Space direction="vertical">
              {workspaceTypeList?.map((workspaceType: WorkspaceType) => (
                <Radio
                  key={workspaceType.id}
                  value={workspaceType.id}
                  onChange={() => {
                    form.setFieldsValue({
                      workspaceTypeName: workspaceType.type_name,
                      workspaceTypeCode: workspaceType.type_code,
                    })
                  }}
                >
                  {workspaceType.type_name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </>
    )
  }
)

export default CreateWorkspaceModalTypeStep
