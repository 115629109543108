import {
  ControlOutlined,
  ExportOutlined,
  EyeOutlined,
  MoreOutlined,
  PlaySquareOutlined,
  PrinterOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { MenuProps } from 'antd/lib'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  algorithmRunStatuses,
  defaultBiomarkConfig,
  workspaceTypes,
} from '../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import notificationService from '../../../services/notificationService'
import {
  AlgorithmRun,
  AlgorithmRunConfigValues,
  BiomarkConfigValues,
  DelConfigValues,
  RegulatorySubmissionConfigValues,
} from '../../../store/reducers/workspaceReducer'
import { routePaths } from '../../RootPage'
import {
  BiomarkConfigurationModal,
  BiomarkLastConfigurationModal,
} from '../BiomarkSmartFunctionDetailsPage/modals'

import {
  DelConfigurationModal,
  DelLastConfigurationModal,
} from '../DelSmartFunctionDetailsPage/modals'
import {
  RegulatorySubmissionConfigurationModal,
  RegulatorySubmissionLastConfigurationModal,
} from '../RegulatorySubmissionFunctionDetailsPage/modals'

interface SmartFunctionActionDropdownProps {
  algorithmRun?: AlgorithmRun
}

const SmartFunctionActionDropdown = ({
  algorithmRun,
}: SmartFunctionActionDropdownProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const location = useLocation()
  const [fetchDone, setFetchDone] = useState(false)
  const { selectedWorkspace, refreshWorkspaceDetails, exportSmartFunctionCsv } =
    useWorkspaceDetails({
      workspaceId,
      preventFetch: true,
    })

  const [selectedAlgorithmRunId, setSelectedAlgorithmRunId] = useState<
    string | undefined
  >(algorithmRunId)

  const { refreshAlgorithmRun, runAlgorithm, cancelAlgorithmRun } =
    useAlgorithmRunDetails({
      algorithmRunId: selectedAlgorithmRunId,
      workspaceId,
      preventFetch: true,
    })

  const navigate = useNavigate()

  const isStatusOngoing = useMemo(
    () =>
      !!selectedWorkspace?.algorithmRuns?.find(
        (run) =>
          run.runStatus === algorithmRunStatuses.ONGOING_AI ||
          run.runStatus === algorithmRunStatuses.ONGOING_INGESTION
      ),
    [selectedWorkspace?.algorithmRuns]
  )

  const isRunsAvailable = useMemo(() => {
    // Check if there are algorithm runs and the status is not ongoing
    if (
      selectedWorkspace?.algorithmRuns &&
      selectedWorkspace?.algorithmRuns?.length > 0 &&
      !isStatusOngoing
    ) {
      return true
    }
    // Check if the workspace type is REFAI
    if (selectedWorkspace?.typeCode === workspaceTypes.REFAI) {
      return true
    }
    // Default to false if none of the above conditions are met
    return false
  }, [isStatusOngoing, selectedWorkspace])

  const dropdownKeys = {
    runAlgorithm: 'runAlgorithm',
    cancelRun: 'cancelRun',
    biomarkConfiguration: 'biomarkConfiguration',
    regsubConfiguration: 'regsubConfiguration',
    delConfiguration: 'delConfiguration',
    viewBiomarkConfiguration: 'viewRunSetup',
    viewRegSubRunSetup: 'viewRegSubRunSetup',
    viewDelRunSetup: 'viewDelRunSetup',
    exportContent: 'exportContent',
    printContent: 'printContent',
  }

  const commonItems: MenuProps['items'] = [
    {
      key: dropdownKeys.runAlgorithm,
      icon: <PlaySquareOutlined />,
      disabled: !isRunsAvailable,
      label: t(
        'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.runAlgorithm'
      ),
    },
    {
      key: dropdownKeys.cancelRun,
      icon: <StopOutlined />,
      disabled: !isStatusOngoing,
      label: t(
        'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.cancelRun'
      ),
    },
    ...(algorithmRunId
      ? [
          {
            key: dropdownKeys.printContent,
            disabled: algorithmRun?.runStatus !== algorithmRunStatuses.COMPLETE,
            icon: <PrinterOutlined />,
            label: t(
              'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.printContent'
            ),
          },
        ]
      : []),
    ...(selectedWorkspace?.typeCode !== workspaceTypes.REGSUB &&
    selectedWorkspace?.typeCode !== workspaceTypes.DEL
      ? [
          {
            key: dropdownKeys.exportContent,
            disabled: algorithmRun?.runStatus !== algorithmRunStatuses.COMPLETE,
            icon: <ExportOutlined />,
            label: t(
              'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.exportContent'
            ),
          },
        ]
      : []),
  ]

  const getAlgorithmRunConfig = () => {
    if (!algorithmRun) return undefined

    switch (selectedWorkspace?.typeCode) {
      case workspaceTypes.BIOMARK:
        return (
          (algorithmRun.config as BiomarkConfigValues) ||
          defaultBiomarkConfig ||
          undefined
        )
      case workspaceTypes.REGSUB:
        return (
          (algorithmRun.config as RegulatorySubmissionConfigValues) || undefined
        )
      case workspaceTypes.DEL:
        return (algorithmRun.config as DelConfigValues) || undefined
      default:
        return undefined
    }
  }

  const algortihmRunConfig: AlgorithmRunConfigValues | undefined = useMemo(
    getAlgorithmRunConfig,
    [algorithmRun, selectedWorkspace]
  )

  const biomarkItems: MenuProps['items'] = [
    {
      key: dropdownKeys.biomarkConfiguration,
      icon: <ControlOutlined />,
      label: (
        <BiomarkConfigurationModal
          config={algortihmRunConfig as BiomarkConfigValues | undefined}
        />
      ),
    },
    {
      key: dropdownKeys.viewBiomarkConfiguration,
      icon: <EyeOutlined />,
      label: (
        <BiomarkLastConfigurationModal
          config={algortihmRunConfig as BiomarkConfigValues | undefined}
        />
      ),
    },
  ]

  const regsubItems: MenuProps['items'] = [
    {
      key: dropdownKeys.regsubConfiguration,
      icon: <ControlOutlined />,
      label: (
        <RegulatorySubmissionConfigurationModal
          config={
            algortihmRunConfig as RegulatorySubmissionConfigValues | undefined
          }
        />
      ),
    },
    {
      key: dropdownKeys.viewRegSubRunSetup,
      icon: <EyeOutlined />,
      label: (
        <RegulatorySubmissionLastConfigurationModal
          config={
            algortihmRunConfig as RegulatorySubmissionConfigValues | undefined
          }
        />
      ),
    },
  ]

  const delItems: MenuProps['items'] = [
    {
      key: dropdownKeys.delConfiguration,
      icon: <ControlOutlined />,
      label: (
        <DelConfigurationModal
          config={algortihmRunConfig as DelConfigValues | undefined}
        />
      ),
    },
    {
      key: dropdownKeys.viewDelRunSetup,
      icon: <EyeOutlined />,
      label: (
        <DelLastConfigurationModal
          config={algortihmRunConfig as DelConfigValues | undefined}
        />
      ),
    },
  ]

  const getItemsBasedOnSmartFunctionTypeCode = () => {
    const selectedItems = [...commonItems] // Clone commonItems

    switch (selectedWorkspace?.typeCode) {
      case workspaceTypes.BIOMARK:
        selectedItems.splice(1, 0, ...biomarkItems) // Insert biomarkItems after the first item
        return selectedItems
      case workspaceTypes.REGSUB:
        selectedItems.splice(1, 0, ...regsubItems) // Insert regsubItems after the first item
        return selectedItems
      case workspaceTypes.DEL:
        selectedItems.splice(1, 0, ...delItems) // Insert delItems after the first item
        return selectedItems
      default:
        return selectedItems
    }
  }

  const items: MenuProps['items'] = getItemsBasedOnSmartFunctionTypeCode()

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }, [])

  useEffect(() => {
    if (algorithmRunId && algorithmRunId !== selectedAlgorithmRunId) {
      setSelectedAlgorithmRunId(algorithmRunId)
      setFetchDone(false)
    }
  }, [algorithmRunId, location.pathname, selectedAlgorithmRunId])

  useEffect(() => {
    if (selectedAlgorithmRunId && !fetchDone) {
      refreshWorkspaceDetails()
      refreshAlgorithmRun()
      setFetchDone(true)
    }
  }, [
    fetchDone,
    refreshAlgorithmRun,
    refreshWorkspaceDetails,
    selectedAlgorithmRunId,
  ])

  const handleAlgorithmRunClick = useCallback(() => {
    runAlgorithm(workspaceId || '', algortihmRunConfig)
      .then((response: AlgorithmRun) => {
        if (!location.pathname.includes(routePaths.WORKSPACE_DETAILS)) {
          let baseRoute = ''
          switch (selectedWorkspace?.typeCode) {
            case workspaceTypes.BIOMARK:
              baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK
              break
            case workspaceTypes.REGSUB:
              baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB
              break
            case workspaceTypes.DEL:
              baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL
              break
            case workspaceTypes.REFAI:
              baseRoute = location.pathname.includes(
                routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
              )
                ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
                : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
              break
          }

          const targetRoute = `${baseRoute}/${selectedWorkspace?.id}/${response.id}`
          navigate(targetRoute)
          setSelectedAlgorithmRunId(response.id)
        } else {
          refreshWorkspaceDetails()
        }

        notificationService.notificationSuccess(
          t('smartFunctionDetailsPage.runStarted')
        )
      })
      .catch((error: Error) => {
        console.error('Error running algorithm:', error)
        notificationService.notificationError(
          t('smartFunctionDetailsPage.runFailed')
        )
      })
  }, [
    runAlgorithm,
    workspaceId,
    algortihmRunConfig,
    location,
    selectedWorkspace,
    navigate,
    refreshWorkspaceDetails,
  ])

  const handleCancelRunClick = useCallback(() => {
    cancelAlgorithmRun(
      selectedWorkspace?.id || '',
      algorithmRun?.id || '',
      navigate
    )
      .then(() => {
        refreshWorkspaceDetails()
        refreshAlgorithmRun()
        notificationService.notificationWarning(
          t('smartFunctionDetailsPage.runCancelled')
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
      })
  }, [
    algorithmRun,
    cancelAlgorithmRun,
    navigate,
    refreshAlgorithmRun,
    refreshWorkspaceDetails,
    selectedWorkspace,
  ])

  const handlePrintContent = useCallback(() => {
    window.print()
  }, [])

  const handleExportRun = () => {
    // eventually isWorkspaceTypeBiomark will be removed when refai is able to export csv
    if (selectedWorkspace && algorithmRun) {
      exportSmartFunctionCsv(selectedWorkspace.id, algorithmRun.id)
    }
  }

  const handleDropdownClick = (key: string) => {
    const handles = {
      [dropdownKeys.runAlgorithm]: handleAlgorithmRunClick,
      [dropdownKeys.cancelRun]: handleCancelRunClick,
      [dropdownKeys.exportContent]: handleExportRun,
      [dropdownKeys.printContent]: handlePrintContent,
    }
    const handler = handles[key]
    if (handler) {
      handler()
    }
  }

  return (
    <Dropdown
      menu={{
        // @ts-ignore: Unreachable code error
        items,
        onClick: ({ key, domEvent }) => {
          domEvent.stopPropagation()
          handleDropdownClick(key)
        },
      }}
      placement="bottomRight"
      trigger={['hover']}
    >
      <Button
        icon={<MoreOutlined />}
        onClick={(e) => {
          handleOpenMenu(e)
        }}
      />
    </Dropdown>
  )
}

export default SmartFunctionActionDropdown
