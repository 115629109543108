import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { smartFunctionService } from '../services'
import notificationService from '../services/notificationService'
import workSpaceService from '../services/workspaceService'
import { RootState } from '../store'
import {
  AlgorithmRun,
  WorkspaceDetails,
  setAlgorithmRunCsv,
  setAlgorithmRunListCsv,
  setSelectedWorkspace,
  setWorkspaceDataSourceListCsv,
  setWorkspaceDataStoreListCsv,
  setWorkspaceDetailsCsv,
  setWorkspaceDetailsLoading,
  setWorkspaceParticipantListCsv,
} from '../store/reducers/workspaceReducer'
import useDownloadFile from './useDownloadFile'

interface useWorkspaceDetailsProps {
  workspaceId?: string
  algorithmRunId?: string
  preventFetch?: boolean
}

const useWorkspaceDetails = ({
  workspaceId,
  preventFetch = false,
}: useWorkspaceDetailsProps) => {
  const dispatch = useDispatch()
  const downloadCsv = useDownloadFile()

  const selectedWorkspace: WorkspaceDetails | undefined = useSelector(
    (state: RootState) => state.workspace.selected
  )

  const selectedAlgorithmRun: AlgorithmRun | undefined = useSelector(
    (state: RootState) => state.workspace.selectedAlgorithmRun
  )

  const workspaceDetailsLoading = useSelector(
    (state: RootState) => state.workspace.workspaceDetailsLoading
  )

  const [error, setError] = useState<AxiosError | Error>()

  const [isFetchDoneWorkspaceDetails, setIsFetchDoneWorkspaceDetails] =
    useState(false)

  const fetchWorkspaceDetails = useCallback(
    (refreshForceCall = false) => {
      if (!workspaceId) {
        return
      }
      if (
        !workspaceDetailsLoading &&
        ((!preventFetch && !isFetchDoneWorkspaceDetails) || refreshForceCall)
      ) {
        dispatch(setWorkspaceDetailsLoading(true))
        workSpaceService
          .getWorkspaceDetails({ workspaceId })
          .then((response: WorkspaceDetails) => {
            dispatch(setSelectedWorkspace(response))
            dispatch(setWorkspaceDetailsLoading(false))

            setIsFetchDoneWorkspaceDetails(true)
          })
          .catch((error: AxiosError | Error) => {
            dispatch(setWorkspaceDetailsLoading(false))

            setIsFetchDoneWorkspaceDetails(true)
            console.error('axios fetch error', error)
            setError(error)
          })
      }
    },
    [
      dispatch,
      workspaceId,
      workspaceDetailsLoading,
      preventFetch,
      isFetchDoneWorkspaceDetails,
    ]
  )

  const refreshWorkspaceDetails = useCallback(async () => {
    fetchWorkspaceDetails(true)
  }, [fetchWorkspaceDetails])

  useEffect(() => {
    fetchWorkspaceDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportSmartFunctionCsv = useCallback(
    (workspaceId: string, algorithmRunId: string) => {
      smartFunctionService
        .exportRun({
          workspaceId,
          algorithmRunId,
        })
        .then((response: string) => {
          const filename = `${selectedWorkspace?.title}_${selectedWorkspace?.algorithmName}_${selectedAlgorithmRun?.completedDate}.csv`
          downloadCsv(response, filename)
          dispatch(setAlgorithmRunCsv(response))
        })
        .catch((error: AxiosError) => {
          notificationService.notificationError(error.message)
        })
    },
    [selectedWorkspace, selectedAlgorithmRun, downloadCsv, dispatch]
  )

  const exportWorkspaceDetailsPanelCsv = useCallback(
    (workspaceId: string) => {
      if (selectedWorkspace) {
        workSpaceService
          .exportWorkspaceDetails({
            workspaceId: workspaceId || selectedWorkspace.id,
          })
          .then((response: string) => {
            const filename = `${selectedWorkspace.title}_panel.csv`
            downloadCsv(response, filename)
            setWorkspaceDetailsCsv(response)
          })
          .catch((error: AxiosError) => {
            console.error(
              'Error fetching workspace details panel CSV data',
              error
            )
          })
      }
    },
    [selectedWorkspace, downloadCsv]
  )

  const exportWorkspaceDataSourcePanelCsv = useCallback(
    (workspaceId: string) => {
      if (
        selectedWorkspace &&
        workspaceId !== undefined &&
        workspaceId !== ''
      ) {
        workSpaceService
          .exportWorkspaceDataSource({
            workspaceId: workspaceId || selectedWorkspace.id,
          })
          .then((response: string) => {
            const filename = `${selectedWorkspace.title}_data_source_panel.csv`
            downloadCsv(response, filename)
            setWorkspaceDataSourceListCsv(response)
          })
          .catch((error: AxiosError) => {
            console.error(
              'Error fetching workspace details panel CSV data',
              error
            )
          })
      }
    },
    [selectedWorkspace, downloadCsv]
  )

  const exportWorkspaceDataStorePanelCsv = useCallback(
    (workspaceId: string) => {
      if (
        selectedWorkspace &&
        workspaceId !== undefined &&
        workspaceId !== ''
      ) {
        workSpaceService
          .exportWorkspaceDataStores({
            workspaceId: workspaceId || selectedWorkspace.id,
          })
          .then((response: string) => {
            const filename = `${selectedWorkspace.title}_data_store_panel.csv`
            downloadCsv(response, filename)
            setWorkspaceDataStoreListCsv(response)
          })
          .catch((error: AxiosError) => {
            console.error(
              'Error fetching workspace details panel CSV data',
              error
            )
          })
      }
    },
    [selectedWorkspace, downloadCsv]
  )

  const exportWorkspaceParticipantsPanelCsv = useCallback(
    (workspaceId: string) => {
      if (
        selectedWorkspace &&
        workspaceId !== undefined &&
        workspaceId !== ''
      ) {
        workSpaceService
          .exportWorkspaceParticipants({
            workspaceId: workspaceId || selectedWorkspace.id,
          })
          .then((response: string) => {
            const filename = `${selectedWorkspace.title}_participants_panel.csv`
            downloadCsv(response, filename)
            setWorkspaceParticipantListCsv(response)
          })
          .catch((error: AxiosError) => {
            console.error(
              'Error fetching workspace details panel CSV data',
              error
            )
          })
      }
    },
    [selectedWorkspace, downloadCsv]
  )

  const exportWorkspaceAlgorithmRunsListCsv = useCallback(
    (workspaceId: string) => {
      if (
        selectedWorkspace &&
        workspaceId !== undefined &&
        workspaceId !== ''
      ) {
        workSpaceService
          .exportWorkspaceAlgorithmRunsList({
            workspaceId,
          })
          .then((response: string) => {
            const filename = `${selectedWorkspace.title}_algorithm_runs.csv`
            downloadCsv(response, filename)
            setAlgorithmRunListCsv(response)
          })
          .catch((error: AxiosError) => {
            console.error(
              'Error fetching workspace algorithm runs list CSV data',
              error
            )
          })
      }
    },
    [downloadCsv, selectedWorkspace]
  )

  return {
    error,
    exportSmartFunctionCsv,
    exportWorkspaceDataSourcePanelCsv,
    exportWorkspaceDataStorePanelCsv,
    exportWorkspaceDetailsPanelCsv,
    exportWorkspaceParticipantsPanelCsv,
    exportWorkspaceAlgorithmRunsListCsv,
    fetchWorkspaceDetails,
    refreshWorkspaceDetails,
    selectedWorkspace,
    workspaceDetailsLoading,
  }
}

export default useWorkspaceDetails
