import UserDeleteOutlined from '@ant-design/icons/UserDeleteOutlined'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  TableBoldColumnContainer,
} from '../../../components'
import { NO_DATA_PLACEHOLDER, privacyTypes } from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import notificationService from '../../../services/notificationService'
import workSpaceService from '../../../services/workspaceService'
import { RootState } from '../../../store'
import { User } from '../../../store/reducers/userReducer'
import { useUsers } from '../../../hooks'

interface ExtendedUser extends User {
  key: string
}

const WorkspaceSettingsPeoplePanel = () => {
  const { workspaceId } = useParams()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
  })
  const { userList } = useUsers({})
  const { currentUser } = useSelector((state: RootState) => state.user)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  )
  const users = useMemo(() => {
    if (userList && selectedWorkspace) {
      if (
        selectedWorkspace.visibility === privacyTypes.PRIVATE &&
        selectedWorkspace.participants
      ) {
        return selectedWorkspace.participants
      } else {
        return userList
      }
    }
  }, [selectedWorkspace, userList])

  const formattedData: ExtendedUser[] =
    useMemo(
      () =>
        users?.map((user: User) => ({
          ...user,
          key: user.id,
        })),
      [users]
    ) || []

  const handleCancel = () => {
    setModalOpen(false)
  }

  const checkIfAbleToDeleteMember = () => {
    if (selectedUserId === selectedWorkspace?.createdBy) {
      notificationService.notificationError(
        t(
          'workspaceDetailsPage.workspaceSettingsPeoplePanel.unableToDeleteOwner'
        )
      )
      return false
    }
    if (selectedUserId === currentUser?.id) {
      notificationService.notificationError(
        t(
          'workspaceDetailsPage.workspaceSettingsPeoplePanel.unableToDeleteSelf'
        )
      )
      return false
    }
    if (selectedWorkspace?.participants) {
      if (selectedWorkspace?.participants.length === 1) {
        notificationService.notificationError(
          t(
            'workspaceDetailsPage.workspaceSettingsPeoplePanel.unableToDeleteLastMember'
          )
        )
        return false
      }
    }
    return true
  }

  const handleConfirm = () => {
    setModalOpen(false)
    setLoading(true)
    if (!checkIfAbleToDeleteMember()) {
      setLoading(false)
      return
    }
    if (selectedWorkspace?.participants) {
      if (selectedWorkspace?.participants.length === 1) {
        notificationService.notificationError(
          t(
            'workspaceDetailsPage.workspaceSettingsPeoplePanel.unableToDeleteLastMember'
          )
        )
        setLoading(false)
        return
      }
    }
    if (workspaceId && selectedWorkspace?.participants && selectedUserId) {
      workSpaceService
        .updateWorkspaceDetails({
          workspaceId,
          participants: selectedWorkspace?.participants
            ?.filter((participant) => participant.id !== selectedUserId)
            .map((participant) => participant.id),
        })
        .then(() => {
          setLoading(false)
          refreshWorkspaceDetails()
          notificationService.notificationSuccess(
            t(
              'workspaceDetailsPage.workspaceSettingsPeoplePanel.deleteParticipantModal.deleteParticipantSuccess'
            )
          )
        })
    }
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setSelectedUserId(id)
    setModalOpen(true)
  }, [])

  const isAdminOrOwner =
    currentUser?.role?.permissions.some((permission) =>
      permission.includes('admin')
    ) || currentUser?.id === selectedWorkspace?.createdBy

  const isPrivateWorkspace =
    selectedWorkspace?.visibility === privacyTypes.PRIVATE

  const columns: ColumnsType<ExtendedUser> = [
    {
      title: t('Name'),
      dataIndex: 'displayName',
      key: 'title',
      width: '60%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id }) => (
        <>
          {isPrivateWorkspace && isAdminOrOwner && (
            <DeleteIconButton
              tooltipTitle={t(
                'workspaceDetailsPage.workspaceSettingsPeoplePanel.deleteParticipantTooltip'
              )}
              handleDeleteClick={(e: MouseEvent) => handleDeleteClick(e, id)}
              icon={<UserDeleteOutlined />}
            />
          )}
        </>
      ),
    },
  ]

  return (
    <div>
      <Table
        data-testid="data-sources-list-table"
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        scroll={{ y: 'calc(100vh - 170px)' }}
      />
      <ConfirmDeleteModal
        open={modalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        loading={loading}
        confirmButtonIcon={<UserDeleteOutlined />}
        confirmButtonLabel={
          t(
            'workspaceDetailsPage.workspaceSettingsPeoplePanel.deleteParticipantModal.confirmButtonLabel'
          ) || ''
        }
      >
        {t(
          'workspaceDetailsPage.workspaceSettingsPeoplePanel.deleteParticipantModal.deleteParticipantMessage'
        )}
      </ConfirmDeleteModal>
    </div>
  )
}

export default WorkspaceSettingsPeoplePanel
