import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../../../components'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import { OfrRunResult } from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

interface QualityReviewRow {
  key: string
  name: string
  deviations: number
}

interface QualityReviewSmartFunctionPanelProps {
  search: string
  deviationsOnly?: boolean
}

const QualityReviewSmartFunctionPanel = ({
  search,
  deviationsOnly,
}: QualityReviewSmartFunctionPanelProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  const formattedData: QualityReviewRow[] = useMemo(() => {
    const runResult = selectedAlgorithmRun?.runResult as OfrRunResult
    const formattedData: QualityReviewRow[] = runResult?.blocks
      .filter((block) => (deviationsOnly ? block.deviations > 0 : true))
      .filter((block) =>
        !!search ? block.ofrBlockName.includes(search || '') : true
      )
      .map((block) => ({
        key: `${block.ofrBlockName}-${block.cell.x1.toString()}-${
          block.cell.x2.toString
        }-${block.cell.y1.toString()}-${block.cell.y2.toString}`,
        name: block.ofrBlockName,
        deviations: block.deviations,
      }))

    return formattedData
  }, [selectedAlgorithmRun, deviationsOnly, search])

  const columns: ColumnsType<QualityReviewRow> = [
    {
      title: t(
        'smartFunctionDetailsPage.qualityReviewTableTitles.documentName'
      ),
      dataIndex: 'name',
      key: 'name',
      width: '60%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },

    {
      title: t('smartFunctionDetailsPage.qualityReviewTableTitles.deviations'),
      key: 'deviations',
      dataIndex: 'deviations',
      width: '40%',

      render: (value) => <>{value || 0}</>,
    },
  ]

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <Table
        loading={loading}
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        scroll={{ y: 'calc(100vh - 202px)' }}
        rowClassName={(record) =>
          record.deviations > 0
            ? 'page-list-table-row pink-background'
            : 'page-list-table-row'
        }
      />
    </SmartFunctionListPanelLayout>
  )
}

export default QualityReviewSmartFunctionPanel
